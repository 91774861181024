// ** Icon imports
import { AccountOutline, BullseyeArrow, CarOutline, HomeOutline, Web } from 'mdi-material-ui'

// ** Type import
import { HorizontalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): HorizontalNavItemsType => [
  {
    title: 'Dashboards',
    icon: HomeOutline,
    children: [
      {
        title: 'My Dashbi',
        path: '/dashboards',
        action: 'read',
        subject: 'home'
      },
      {
        title: 'DS Sales Performance',
        path: '/dashboards/ds-sales-performance',
        action: 'read',
        subject: 'dsSalesPerformance'
      },
      {
        title: 'Sales Performance',
        path: '/dashboards/sales-performance',
        action: 'read',
        subject: 'salesPerformance'
      },
      {
        title: 'Internet Performance',
        path: '/dashboards/internet-performance',
        action: 'read',
        subject: 'internetPerformanceDashboard'
      },
      {
        title: 'DS Service Performance',
        path: '/dashboards/service-performance',
        action: 'read',
        subject: 'servicePerformanceDashboard'
      },
      {
        title: 'Google Analytics',
        path: '/dashboards/google-analytics',
        action: 'read',
        subject: 'gaPerformanceDashboard'
      },
      {
        title: 'F&I Report',
        path: '/dashboards/finance',
        action: 'read',
        subject: 'financeReport'
      },
      {
        title: 'Service Report',
        path: '/dashboards/service',
        action: 'read',
        subject: 'serviceReport'
      }
    ]
  },
  {
    title: 'Dealership',
    icon: CarOutline,
    children: [
      {
        title: 'Franchises',
        action: 'manage',
        subject: 'franchises',
        children: [
          {
            title: 'List',
            path: '/franchises',
            action: 'manage',
            subject: 'franchises'
          },
          {
            title: 'Create',
            path: '/franchises/new',
            action: 'manage',
            subject: 'franchises'
          }
        ]
      },
      {
        title: 'Teams',
        path: '/teams',
        action: 'manage',
        subject: 'users'
      },
      {
        title: 'Agents',
        path: '/agents',
        action: 'manage',
        subject: 'agents'
      }
    ]
  },
  {
    title: 'Report Settings',
    icon: BullseyeArrow,
    children: [
      {
        title: 'Internet Goals',
        path: '/goals/1',
        action: 'manage',
        subject: 'goals'
      },
      {
        title: 'Sales Goals',
        action: 'monthlyGoals',
        subject: 'users',
        children: [
          {
            title: 'List',
            path: '/monthly-goals',
            action: 'manage',
            subject: 'monthlyGoals'
          },
          {
            title: 'Create',
            path: '/monthly-goals/new',
            action: 'manage',
            subject: 'monthlyGoals'
          }
        ]
      },
      {
        title: 'Vendor Costs',
        action: 'manage',
        subject: 'vendorCosts',
        children: [
          {
            title: 'List',
            path: '/vendor-costs',
            action: 'manage',
            subject: 'vendorCosts'
          },
          {
            title: 'Create',
            path: '/vendor-costs/new',
            action: 'manage',
            subject: 'vendorCosts'
          }
        ]
      }
    ]
  },
  {
    title: 'Others',
    icon: Web,
    children: [
      {
        title: 'Sales Leads',
        path: '/sales-leads',
        action: 'manage',
        subject: 'salesLeads'
      },
      {
        title: 'Appointments',
        path: '/appointments',
        action: 'manage',
        subject: 'appointments'
      },
      {
        title: 'Web Leads',
        path: '/web-leads',
        action: 'manage',
        subject: 'webLeads'
      },
      {
        title: 'Sales Lead Status Logs',
        path: '/sales-lead-status-logs',
        action: 'manage',
        subject: 'salesLeadStatusLogs'
      },
      {
        title: 'Services',
        path: '/vehicle-services',
        action: 'manage',
        subject: 'vehicleServices'
      },
      {
        title: 'Service Details',
        path: '/vehicle-service-details',
        action: 'manage',
        subject: 'vehicleServices'
      },
      {
        title: 'Customers',
        path: '/customers',
        action: 'manage',
        subject: 'customers'
      },
      {
        title: 'Marketing Campaigns',
        path: '/marketing-campaigns',
        action: 'manage',
        subject: 'marketingCampaigns'
      },
      {
        title: 'Marketing Channels',
        path: '/marketing-channels',
        action: 'manage',
        subject: 'marketingChannels'
      },
      {
        title: 'Inventories',
        path: '/inventories',
        action: 'manage',
        subject: 'dataImports'
      }
    ]
  },
  {
    title: 'Settings',
    icon: AccountOutline,
    children: [
      {
        title: 'Data Imports',
        path: '/data-imports',
        action: 'manage',
        subject: 'dataImports'
      },
      {
        title: 'Import DS Teams',
        path: '/data-imports/new',
        action: 'manage',
        subject: 'dataImports'
      },
      {
        title: 'Integrations',
        path: '/integrations',
        action: 'manage',
        subject: 'dataImports'
      },
      {
        title: 'Users',
        action: 'manage',
        subject: 'users',
        children: [
          {
            title: 'List',
            path: '/users',
            action: 'manage',
            subject: 'users'
          },
          {
            title: 'Create',
            path: '/users/new',
            action: 'manage',
            subject: 'users'
          }
        ]
      },
      {
        title: 'Access Profiles',
        action: 'manage',
        subject: 'accessProfiles',
        children: [
          {
            title: 'List',
            path: '/access-profiles',
            action: 'manage',
            subject: 'accessProfiles'
          },
          {
            title: 'Create',
            path: '/access-profiles/new',
            action: 'manage',
            subject: 'accessProfiles'
          }
        ]
      },
      {
        title: ' Global Settings',
        subject: 'globalSettings',
        action: 'manage',
        children: [
          {
            title: 'List',
            path: '/global-settings',
            subject: 'globalSettings',
            action: 'manage'
          },
          {
            title: 'Create',
            path: '/global-settings/new',
            subject: 'globalSettings',
            action: 'manage'
          }
        ]
      }
    ]
  }
]

export default navigation
